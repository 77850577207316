import { graphql, PageProps } from "gatsby"
import { withoutAuthentication } from "@app/hoc/Authentication"
import Page from "@app/components/Account/LoginRegister/AccountLoginRegister"

export type Props = PageProps<GatsbyTypes.PageAccountLoginAndRegisterQuery, GatsbyTypes.PageAccountLoginAndRegisterVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountLoginAndRegister {
    page: sanityPageAccountLoginAndRegister {
      titleLogin
      titleRegister
      descriptionLogin: _rawDescriptionLogin(resolveReferences: { maxDepth: 10 })
      descriptionRegister: _rawDescriptionRegister(resolveReferences: { maxDepth: 10 })
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withoutAuthentication(Component)
