import { navigate } from "gatsby"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

export const withAuthentication =
  (Component: React.FC<any>) =>
  ({ location, ...props }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()
    const { getStorage, removeStorage } = useStorage()
    const {
      settings: { keys, params, routes },
    } = useConfigContext()

    const customerTokens = getStorage(keys.customer)

    if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
      removeStorage(keys.customer)

      if (isBrowser) {
        const route = `${routes.LOGIN_REGISTER}${
          location?.pathname !== routes.DASHBOARD ? `?${params.continue}=${location?.pathname}` : ""
        }`

        navigate(route, { replace: true })
      }

      return null
    }

    return <Component {...props} location={location} />
  }

export const withoutAuthentication =
  (Component: React.FC<any>) =>
  ({ ...props }: any) => {
    const {
      helpers: { getUrlParameter, isBrowser },
    } = useCore()
    const { getStorage } = useStorage()
    const {
      settings: { keys, params, routes },
    } = useConfigContext()

    const customerTokens = getStorage(keys.customer)

    if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
      if (isBrowser) {
        const path = getUrlParameter(params.continue)
        const checkoutUrl = getUrlParameter(params.checkout)
        const isCheckout = !!checkoutUrl
        const route = isCheckout ? routes.CHECKOUT : path ? path : routes.DASHBOARD

        navigate(route, { replace: true })
      }
    }

    return <Component {...props} />
  }
