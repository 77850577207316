import { memo } from "react"
import { FormControl, Button, Stack, Box, Checkbox } from "@chakra-ui/react"
import { useCustomerRegister } from "@app/hooks/useCustomer"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"

type AccountRegisterFormProps = {
  locales?: any
}

const AccountRegisterForm: React.FC<AccountRegisterFormProps> = ({ locales }) => {
  const { handleSubmit: handleCustomerRegister, handleChange, data, loading, errors: functionErrors } = useCustomerRegister()

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleCustomerRegister(event),
  })

  return (
    <Box id="create_account" as="form" onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <ControlledInput type={FIELD_TYPES.FIRST_NAME} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />
        <ControlledInput type={FIELD_TYPES.LAST_NAME} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />
        <ControlledInput type={FIELD_TYPES.EMAIL} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />
        <ControlledInput type={FIELD_TYPES.PASSWORD} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />

        {locales?.accountRegisterSubscribeText && (
          <FormControl id="acceptsMarketing" onChange={handleChange}>
            <Checkbox name="acceptsMarketing" defaultChecked checked={!!data?.acceptsMarketing}>
              {locales?.accountRegisterSubscribeText}
            </Checkbox>
          </FormControl>
        )}

        {locales?.accountRegisterSubmitBtn && (
          <Button type="submit" isDisabled={loading} isLoading={loading} fontSize="xl">
            {locales?.accountRegisterSubmitBtn}
          </Button>
        )}

        <FunctionErrors errors={functionErrors} />
      </Stack>
    </Box>
  )
}

const MemoAccountRegisterForm = memo(AccountRegisterForm)
export { MemoAccountRegisterForm as AccountRegisterForm }
