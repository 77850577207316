import { Stack, Box, Container } from "@chakra-ui/react"
import { useContent } from "@app/hooks/useContent"
import { AccountLoginForm } from "./AccountLoginForm"
import { AccountRegisterForm } from "./AccountRegisterForm"
import { AccountBox } from "@components/Account/AccountBox"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { memo } from "react"
import Sections from "@components/Sections/Sections"

import type { Props } from "@app/pages/account/login"
import type { PageProps } from "@root/types/global"

const AccountLoginRegister: React.FC<PageProps<Props>> = ({ page }) => {
  const { getContent } = useContent()
  const loginPageContent = getContent({ content: page?.descriptionLogin })
  const registerPageContent = getContent({ content: page?.descriptionRegister })

  const locales = useLocale(LOCALE_KEYS.ACCOUNT)
  const hasSections = page?.sections?.length

  return (
    <>
      <Box as="section" pt={[21, 21, 30]} pb={[12, 12, 36]}>
        <Container maxW="1105px">
          <Stack direction={["column", "row"]} spacing={[6, 4, 4]} alignItems="stretch">
            <AccountBox title={page?.titleLogin} content={loginPageContent} width={["full", "half"]}>
              <AccountLoginForm locales={locales} />
            </AccountBox>
            <AccountBox title={page?.titleRegister} content={registerPageContent} bg="brand.darkerSand" width={["full", "half"]}>
              <AccountRegisterForm locales={locales} />
            </AccountBox>
          </Stack>
        </Container>
      </Box>
      {hasSections && <Sections page={{ content: page?.sections }} />}
    </>
  )
}

export default memo(AccountLoginRegister)
