import { memo } from "react"
import { Button, Stack } from "@chakra-ui/react"
import { useCustomerLogin } from "@app/hooks/useCustomer"
import { useConfigContext } from "@app/providers/config"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"
import { CustomLink } from "@app/components/Link"

type AccountLoginFormProps = {
  locales?: any
}

const AccountLoginForm: React.FC<AccountLoginFormProps> = ({ locales }) => {
  const { handleSubmit: handleCustomerLogin, handleChange, data, loading, errors: functionErrors } = useCustomerLogin()

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleCustomerLogin(event),
  })

  const {
    settings: { routes },
  } = useConfigContext()

  return (
    <Stack id="sign_in" as="form" spacing={4} onSubmit={handleSubmit}>
      <ControlledInput type={FIELD_TYPES.EMAIL} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />
      <ControlledInput type={FIELD_TYPES.PASSWORD} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />

      {locales?.accountLoginForgotPasswordTxt && (
        <CustomLink styles={{ fontSize: "sm" }} to={routes.FORGOT}>
          {locales?.accountLoginForgotPasswordTxt}
        </CustomLink>
      )}

      {locales?.accountLoginSubmitBtn && (
        <Button isDisabled={loading} isLoading={loading} type="submit" w="full" fontSize="xl">
          {locales?.accountLoginSubmitBtn}
        </Button>
      )}

      <FunctionErrors errors={functionErrors} />
    </Stack>
  )
}

const MemoAccountLoginForm = memo(AccountLoginForm)
export { MemoAccountLoginForm as AccountLoginForm }
