import { Box, Heading } from "@chakra-ui/react"

type AccountBoxProps = {
  title?: string
  content: any
  bg?: string
  children: React.ReactNode
  width?: any
}

const AccountBox: React.FC<AccountBoxProps> = ({ title, content, bg = "brand.apricot", width = "auto", children }) => {
  return (
    <Box bg={bg} w={width}>
      <Box w={["85%", "85%", "65%"]} mx="auto" pt={[8, 8, 11]} pb={[12, 12, 16]}>
        {title && (
          <Heading as="p" size="h3" textAlign="center" pb={4}>
            {title}
          </Heading>
        )}
        {content && (
          <Box
            textAlign="center"
            mb={4}
            sx={{
              p: {
                fontSize: "md",
                pb: 0,
                lineHeight: "normal",
              },
            }}
          >
            {content}
          </Box>
        )}
        {children}
      </Box>
    </Box>
  )
}

export { AccountBox }
